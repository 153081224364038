import React, { useContext, useState, useEffect } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { AccountContext } from "./Account";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { resetpassword } = useContext(AccountContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setEmail(id);
    console.log("theMail", id);
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("passwords do not match");
      return;
    }
    resetpassword(email, code, password)
      .then((data) => {
        console.log("data is", data);
        navigate("/");
      })
      .catch((err) => {
        console.log("the ERR is ", err);
      });
  };
  return (
    <Container>
      <br />
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>verification Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};
export default ForgetPassword;
