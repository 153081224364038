import React, { createContext, useEffect, useState } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import Pool from "../UserPool";
import { useNavigate } from "react-router-dom";

const AccountContext = createContext();

const Account = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLoggedIn(true);
    }
  }, []);
  const navigate = useNavigate();

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };
  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
          setIsLoggedIn(true);
          localStorage.setItem("token", data.accessToken.jwtToken);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  };

  const resetpassword = async (Username, code, password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      user.confirmPassword(code, password, {
        onSuccess: (data) => {
          resolve(data);
          console.log("data from promise is password changed", data);
        },
        onFailure: (err) => {
          reject(err);
          console.log("err is ", err);
        },
      });
    });
  };
  const requestVerificationCode = async (Username) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
          console.log("data from promise is ", data);
        },
        onFailure: (err) => {
          reject(err);
          console.log("err is ", err);
        },
        inputVerificationCode: (data) => {
          console.log("input code", data);
          resolve(data);
        },
      });
    });
  };
  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      setIsLoggedIn(false);
      localStorage.removeItem("token");
      navigate("/");
    }
  };
  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        isLoggedIn,
        requestVerificationCode,
        resetpassword,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
export { Account, AccountContext };
