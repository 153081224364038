import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [emailToReset, setEmailToReset] = useState("");
  const [password, setPassword] = useState("");
  const { authenticate, requestVerificationCode } = useContext(AccountContext);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("on submit", email, password);

    authenticate(email, password)
      .then((data) => {
        console.log("data after retrieved", data);
        navigate("/createuser");
      })
      .catch((err) => {
        console.log("Err After Retrieved", err);
      });
  };

  const onSendCode = (e) => {
    e.preventDefault();
    // console.log(props.whenHandleUser);
    requestVerificationCode(emailToReset)
      .then((data) => {
        console.log("Data received", data);
        navigate("/resetpassword/" + emailToReset);
      })
      .catch((err) => {
        console.log("Err received", err);
      });
  };
  return (
    <Container>
      <br />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter your email</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSendCode}>
          <Modal.Body>
            {" "}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={emailToReset}
                onChange={(e) => setEmailToReset(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Send Verification code
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <span onClick={handleShow} className="forget-password">
            Forgot Password?
          </span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default SignIn;
