import SignIn from "./components/Login";
import Header from "./components/shared/Header";
import { Account } from "./components/Account";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ResetPassword from "./components/ResetPassword";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import CreateUser from "./components/CreateUser";
function App() {
  return (
    <Router>
      <Account>
        <Header />
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/resetpassword/:id" element={<ResetPassword />} />
          <Route path="/createuser" element={<CreateUser />} />
        </Routes>
      </Account>
    </Router>
  );
}

export default App;
