import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CreateUser = () => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [subscriptionUntil, setSubscriptionUntil] = useState("");
  const [extraFieldOne, setExtraFieldOne] = useState("");
  const [extraFieldTwo, setExtraFieldTwo] = useState("");
  const [extraFieldThree, setExtraFieldThree] = useState("");
  const [extraFieldFour, setExtraFieldFour] = useState("");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const resetAllFields = () => {
    setEmail("");
    setConfirmEmail("");
    setFirstName("");
    setLastName("");
    setGender("");
    setPaymentAmount(0);
    setSubscriptionUntil("");
    setExtraFieldOne("");
    setExtraFieldTwo("");
    setExtraFieldThree("");
    setExtraFieldFour("");
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      console.log("validity false");
    } else {
      const requestData = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        gender: gender,
        payment_amount: paymentAmount,
        subscription_until: subscriptionUntil,
        extra_field_1: extraFieldOne,
        extra_field_2: extraFieldTwo,
        extra_field_3: extraFieldThree,
        extra_field_4: extraFieldFour,
      };

      fetch("https://new.itismyexperience.com/admincreateuser", {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
          access_token: localStorage.getItem("token"),
          admin_user: true,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          resetAllFields();
          setValidated(false);
          console.log("response is", response, response.statusText);
          if (response.statusText === "Created") {
            alert("User Created Successfully");
          }
          else if (response.status === 461) {
            alert("Your session has expired.\nPlease login again.")
            navigate("/");
          }
          else {
            response.text().then((errorMessage) => {
              if(errorMessage){
                const startIndex = errorMessage.indexOf("error:") + 6;
                const endIndex = errorMessage.length;
                const error = errorMessage.substring(startIndex, endIndex).trim();
              alert(error);
              }
              else{
                alert("Something when wrong. User not created.\n Try logging out and loging in.\n If problem persists, contact tech team.");
              }
            });
          }
        })
        .catch((error) => {
          console.error("Request failed", error);
        });
    }
    setValidated(true);
  };
  const handleGenderChange = (e) => {
    const value = e.target.value.toUpperCase(); // Convert value to uppercase
    setGender(value);
  };
  return (
    <Container>
      <br />
      <Form
        noValidate
        onSubmit={onSubmit}
        validated={validated}
        className="row"
      >
        <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text className="text-red">required</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
          <Form.Label>Confirm Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Confirm Email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
          />
          <Form.Text className="text-red">required</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Form.Text className="text-red">required</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Form.Text className="text-red">required</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicSelect">
          <Form.Label>Gender</Form.Label>

          <div className="">
            <Form.Check
              inline
              label="Male"
              name="group1"
              type="radio"
              value="MALE"
              required
              onChange={handleGenderChange}
              checked={gender === "MALE"}
            />
            <Form.Check
              inline
              label="Female"
              name="group1"
              type="radio"
              value="FEMALE"
              required
              onChange={handleGenderChange}
              checked={gender === "FEMALE"}
            />
          </div>
          <Form.Text className="text-red">required</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
          <Form.Label>Payment Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Payment Amount"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
          <Form.Label>Subscription Until</Form.Label>
          <Form.Control
            type="date"
            placeholder="Subscription until"
            value={subscriptionUntil}
            onChange={(e) => setSubscriptionUntil(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
          <Form.Label>Extra 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Text 1"
            value={extraFieldOne}
            onChange={(e) => setExtraFieldOne(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
          <Form.Label>Extra 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Extra 2 "
            value={extraFieldTwo}
            onChange={(e) => setExtraFieldTwo(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
          <Form.Label>Extra 3</Form.Label>
          <Form.Control
            type="text"
            placeholder="Extra 3 "
            value={extraFieldThree}
            onChange={(e) => setExtraFieldThree(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
          <Form.Label>Extra 4</Form.Label>
          <Form.Control
            type="text"
            placeholder="Extra 4 "
            value={extraFieldFour}
            onChange={(e) => setExtraFieldFour(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Create User
        </Button>
      </Form>
    </Container>
  );
};
export default CreateUser;
