import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "../Account";
const Header = () => {
  const { logout, isLoggedIn } = useContext(AccountContext);

  return (
    <Navbar className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt=""
            src="/logo.png"
            width="200"
            height="auto"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar className="bg-body-tertiary">
          <Container>
            {isLoggedIn ? (
              <Button onClick={logout} variant="outline-success">
                Logout
              </Button>
            ) : (
              ""
            )}
          </Container>
        </Navbar>
      </Container>
    </Navbar>
  );
};

export default Header;
